import React, { useEffect } from "react"
import "./header.scss"
import BackgroundImage from "gatsby-background-image"
import CustomMarkdown from "../../utils/customMarkdown"
import { GatsbyImage } from "gatsby-plugin-image"


const Header = ({ title, subtitle, secondarySubtitle, CTAText, CTAUrl, Disclaimer, Background, HeaderHeight, CTA_nofollow, SecondaryImage, BackgroundColor, TextAlign }) => {

    var headerStyle
    Background ? headerStyle = { backgroundImage: "url(" + Background + ")" } : headerStyle = ""

    var headerHeightStyle
    switch (HeaderHeight) {
        case "small":
            headerHeightStyle = { minHeight: '200px' }
            break;
        case "medium":
            headerHeightStyle = { minHeight: '300px' }
            break;
        case "large":
            headerHeightStyle = { minHeight: '600px' }
            break;

        default:
            break;
    }


    useEffect(() => {
        if (Background && Background.fluid && Background.fluid && Background.fluid.srcWebp) {
            const preloadLink = document.createElement("link");
            preloadLink.href = Background.fluid.srcWebp;
            preloadLink.rel = "preload";
            preloadLink.as = "image";
            document.head.appendChild(preloadLink);
        }
        
    }, [])

    return (
        <>
            {!BackgroundColor || BackgroundColor == 'NA' || BackgroundColor == 'transparent' ?
                <BackgroundImage Tag="section" backgroundColor={"#040e18"} role="img" className="" fluid={Background.fluid || ["rgba(0,0,0,0)"]}>
                    <div className={"header header-container-gradient "+ `${BackgroundColor ? BackgroundColor : ""}` + " lateral-padding "} style={headerHeightStyle}>

                        <div className={TextAlign === "Empty" ? "header-content" : "header-content-left"}>

                            {
                                title.split('#').length > 2 ?
                                    <div className="title-blocks">
                                        <span className="split-title">{title.split('#')[0]}</span>
                                        <h1 className="header__title__split">{title.split('#')[1]}</h1>
                                        {title.split('#')[2] ? <span className="split-title">{title.split('#')[2]}</span> : ""}
                                    </div>
                                    : <h1 className="header__title">{title}</h1>
                            }

                            
                            {SecondaryImage ?
                                <div className="secondary-image">
                                    <GatsbyImage image={SecondaryImage?.gatsbyImageData} alt="header image" ></GatsbyImage>
                                </div>
                                : ''
                            }

                            {subtitle ? <CustomMarkdown className="header__subtitle" children={subtitle}></CustomMarkdown> : ""}

                            {
                                CTAText ?
                                    <div className="header__cta">
                                        <a href={CTAUrl} className="cta" rel={CTA_nofollow ? "nofollow" : ""}>{CTAText}</a>
                                    </div>
                                    : ""
                            }


                            {secondarySubtitle ? <CustomMarkdown className="header_secondary_subtitle" children={secondarySubtitle}></CustomMarkdown> : ""}

                            

                            {
                                Disclaimer ?
                                    <div className="cpg-disclaimer">
                                        
                                        <CustomMarkdown className="disclaimer-paragraph">{Disclaimer}</CustomMarkdown>
                                    </div>
                                    : ""
                            }
                        </div>
                    </div>
                </BackgroundImage>
                : //if background is set light or dark
                <div className={`header header-container-gradient lateral-padding ${BackgroundColor == 'light' ? 'header-light' : 'header-dark'}`} style={headerHeightStyle}>

                    <div className={TextAlign === "Empty" ? "header-content" : "header-content-left"}>

                        {
                            title.split('#').length > 2 ?
                                <div className="title-blocks">
                                    <span className="split-title">{title.split('#')[0]}</span>
                                    <h1 className="header__title__split">{title.split('#')[1]}</h1>
                                    {title.split('#')[2] ? <span className="split-title">{title.split('#')[2]}</span> : ""}
                                </div>
                                : <h1 className="header__title">{title}</h1>
                        }
                        
                        {SecondaryImage ?
                            <div className="secondary-image">
                                <GatsbyImage image={SecondaryImage?.gatsbyImageData} alt="header image" ></GatsbyImage>
                            </div>
                            : ''
                        }

                        {subtitle ? <CustomMarkdown className="header__subtitle" children={subtitle}></CustomMarkdown> : ""}


                        {
                            CTAText ?
                                <div className="header__cta">
                                    <a href={CTAUrl} className="cta" rel={CTA_nofollow ? "nofollow" : ""}>{CTAText}</a>
                                </div>
                                : ""
                        }


                        {secondarySubtitle ? <CustomMarkdown className="header_secondary_subtitle" children={secondarySubtitle}></CustomMarkdown> : ""}


                        {
                            Disclaimer ?
                                <div className="cpg-disclaimer">
                                    <p className="disclaimer-paragraph">{Disclaimer}</p>
                                </div>
                                : ""
                        }
                    </div>
                </div>
            }

        </>
    )
}

export default Header